import React, { useEffect, useState, useRef } from 'react';
import './styles/timelineManager.css';

import EditServiceModal from './components/EditServiceModalCron.js'

const Timeline = () => {
  const [servicesData, setServicesData] = useState([]);
  const [timelineData, setTimelineData] = useState([]);
  const [viewMode, setViewMode] = useState('higher'); // 'timeline' or 'higher'


  const timelineRef = useRef(null);
  const scrollbarRef = useRef(null);
  const thumbRef = useRef(null);

  const [scrollbarWidth, setScrollbarWidth] = useState(0); // The width of the thumb
  const [scrollPosition, setScrollPosition] = useState(0); // The scroll position (percentage)
  const [scrollTrackWidth, setScrollTrackWidth] = useState(800); // Width of the scrollable track (e.g., 1500px)

  const [selectedService, setSelectedService] = useState(null);

  const handleEditClick = (service) => setSelectedService(service);

  const handleSaveService = (updatedService, type) => {
    setServicesData((prevData) => {
      switch (type) {
        case 'default':
          // Default behavior: update only the selected service
          return prevData.map((service) =>
            service.id === updatedService.id ? updatedService : service
          );
  
        case 'isolated':
          // Get the previous state of the service (before the update)
          const previousService = prevData.find(service => service.id === updatedService.id);
          const oldPredecessor = previousService ? previousService.predecessors[0] : null;
  
          // Update the selected service first
          const updatedServices = prevData.map((service) =>
            service.id === updatedService.id ? updatedService : service
          );
  
          // For the "isolated" update, only modify the predecessors of services that depend on the updated one
          return updatedServices.map((service) => {
            if (service.predecessors?.includes(updatedService.id)) {
              // If this service has the edited service as a predecessor, set its predecessor to the old predecessor (before update)
              return {
                ...service,
                predecessors: service.predecessors.map((predecessorId) =>
                  predecessorId === updatedService.id ? oldPredecessor : predecessorId
                ),
              };
            }
            return service;
          });
  
        default:
          // If no valid type is provided, return the previous data unchanged
          return prevData;
      }
    });
  };
  
  

  const handleCloseModal = () => setSelectedService(null);


  // Fetch services data on mount
  useEffect(() => {
    const storedServices = JSON.parse(sessionStorage.getItem('servicesData')) || [];
    
    if (storedServices.length > 0) {
        // Add consecutive predecessors
        const servicesWithPredecessors = storedServices.map((service, index) => {
            const predecessorId = index > 0 ? `service-${index}` : null;
            return {
                ...service,
                id: `service-${index + 1}`,
                predecessors: predecessorId ? [predecessorId] : [],
            };
        });

        setServicesData(servicesWithPredecessors);
        sessionStorage.removeItem('servicesData');
    }
}, []);

// Calculate position and length of each service's timeline bar whenever `servicesData` changes
useEffect(() => {
    if (servicesData.length > 0) {
        const serviceMap = new Map();
        let previousServiceEndTime = 0;
        const orderedServices = [];
        const remainingServices = [...servicesData]; // Copy all services for processing

        servicesData.forEach(service => service.highlight = false);

        while (remainingServices.length > 0) {
            let addedService = false;

            // Attempt to place each service in the correct order
            for (let i = 0; i < remainingServices.length; i++) {
                const service = remainingServices[i];
                const predecessorId = service.predecessors && service.predecessors[0] ? service.predecessors[0] : null;

                // Check if the predecessor is placed, or if no predecessor is needed
                if (!predecessorId || serviceMap.has(predecessorId)) {
                    // Determine the start and end times based on the predecessor's end time
                    const predecessorEnd = predecessorId ? serviceMap.get(predecessorId).endTime : previousServiceEndTime;
                    const startDay = predecessorEnd || previousServiceEndTime;
                    const endTime = startDay + service.duration * service.quantity;

                    // Construct the timeline entry for this service
                    const timelineService = {
                        ...service,
                        startDay,
                        endTime,
                    };

                    // Store the service in the map and add to the ordered list
                    serviceMap.set(service.id, timelineService);
                    previousServiceEndTime = endTime;
                    orderedServices.push(timelineService);

                    // Remove this service from remaining list as it's now placed
                    remainingServices.splice(i, 1);
                    i--; // Adjust index after removal
                    addedService = true;
                }
            }

            // Break if no service could be added, to prevent infinite loops
            if (!addedService) {
                console.warn("Unresolved dependency detected. Check for circular dependencies or missing predecessors.");
                break;
            }
        }

        // Finding the service with the highest end time
        const maxEndService = orderedServices.reduce((maxService, service) =>
          service.endTime > (maxService?.endTime || 0) ? service : maxService,
        null);

        // Highlight the critical path (max service and its predecessors)
        if (maxEndService) {
            let currentService = maxEndService;

            // Loop through predecessors and mark as highlighted (red)
            while (currentService) {
                currentService.highlight = true; // Mark service as highlighted
                const predecessorId = currentService.predecessors?.[0];
                currentService = predecessorId ? serviceMap.get(predecessorId) : null;
            }
        }

        setTimelineData(orderedServices);
        
    }
}, [servicesData]);



const totalDuration = timelineData.length > 0 
? Math.max(...timelineData.map(service => service.endTime))
: 0;

const sortedData = viewMode === 'higher' 
  ? [...timelineData].sort((a, b) => (b.duration * b.quantity) - (a.duration * a.quantity)) 
  : timelineData;

  // Update scrollbar width and scroll position
  useEffect(() => {
    const updateScrollbar = () => {
      const container = timelineRef.current;
      const visibleWidth = container.clientWidth;
      const totalWidth = container.scrollWidth;

      // Calculate the width of the thumb (as percentage of the total scrollable area)
      const thumbWidth = (visibleWidth / totalWidth) * 100;
      setScrollbarWidth(thumbWidth); // Set thumb width as percentage
    };

    const handleScroll = () => {
      const container = timelineRef.current;
      const totalWidth = container.scrollWidth;
      const visibleWidth = container.clientWidth;
      setScrollPosition((container.scrollLeft / (totalWidth - visibleWidth)) * 100);
    };

    const container = timelineRef.current;
    if (container) {
      updateScrollbar();
      container.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', updateScrollbar); // Update on resize
    }

    // Cleanup event listeners on unmount
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', updateScrollbar);
    };
  }, [servicesData]);

  const handleThumbDrag = (e) => {
    const container = timelineRef.current;
    const totalWidth = container.scrollWidth;
    const visibleWidth = container.clientWidth;
    const scrollDistance = e.clientX - scrollbarRef.current.getBoundingClientRect().left;
    const scrollRatio = scrollDistance / scrollbarRef.current.offsetWidth;
    container.scrollLeft = scrollRatio * (totalWidth - visibleWidth);
  };

  const scrollbar = (
    <div 
      className="fake-scrollbar" 
      style={{
        position: 'fixed',
        top: '80px',
        left: '600px',
        width: `${scrollTrackWidth + (scrollTrackWidth * (scrollbarWidth / 100))}px`, // Calculate total width in pixels
        height: '10px', 
        backgroundColor: '#bbb',
        borderRadius: '4px',
        zIndex: '998',
      }}
    >
      <div
        ref={scrollbarRef}
        className="custom-scrollbar"
        style={{
          position: 'relative',
          width: `${scrollTrackWidth}px`, // True scroll track width
          height: '100%',
          backgroundColor: '#bbb',
          borderRadius: '4px',
          
          zIndex: '999',
        }}
        onMouseDown={(e) => {
          e.preventDefault();
          document.addEventListener('mousemove', handleThumbDrag);
          document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', handleThumbDrag);
          });
        }}
      >
        {/* Thumb contained within custom-scrollbar */}
        <div
          ref={thumbRef}
          className="thumb"
          style={{
            position: 'absolute',
            top: 0,
            left: `${scrollPosition}%`, 
            width: `${scrollbarWidth}%`, 
            height: '100%',
            backgroundColor: '#4caf50',
            borderRadius: '4px',
            zIndex: '1000',
          }}
        />
      </div>
    </div>
  );
  
  

  return (
    <div className="timeline-manager">

      {/* Render modal only if a service is selected */}
      {selectedService && (
        <div className="modal-overlay" onClick={handleCloseModal}> {/* Click outside to close */}
          <div className="modal-content" onClick={(e) => e.stopPropagation()}> {/* Prevent click from propagating */}
            <EditServiceModal
              service={selectedService}
              onSave={handleSaveService}
              onClose={handleCloseModal}
              servicesData={servicesData}
            />
          </div>
        </div>
      )}

      <div className="timeline-controller">
        <h2>Total Duration: {(totalDuration / 8).toFixed(2)} dias</h2>
        <button onClick={() => setViewMode(viewMode === 'timeline' ? 'higher' : 'timeline')}>
        Toggle View Mode
      </button>
      </div>

      <div className="timeline-data">
        <div className="services-list">
          {sortedData.map((service, idx) => (
            <div className="service" key={idx}>
              <div className="edit">
                <button onClick={() => handleEditClick(service)}>Edit</button>
              </div>
              <div className="code">{service.service_code}</div>
              {service.originalService ? (<div>{service.originalService}</div>) : ''}
              <div className="description">{service.description}</div>
              <div className="measure">{service.unidade}</div>
              <div className="quantity">{service.quantity}</div>
              <div className="hours">{(service.quantity * service.duration).toFixed(2)}</div>
            </div>
          ))}
        </div>
        <div className="timeline-graph" ref={timelineRef}>
          {scrollbar} {/* Custom scrollbar */}
          
          {sortedData.map((service) => (
            <div className="timeline-bar-container" key={service.id}>
              <div
                className="timeline-bar"
                style={{
                  left: viewMode === 'timeline' 
                    ? `${service.startDay * 10}px`
                    : `0px`, 
                  width: viewMode === 'timeline'
                    ? `${(service.endTime - service.startDay) * 10}px`
                    : `${(service.duration * service.quantity) * 10}px`,
                  backgroundColor: service.highlight ? 'red' : '',
                }}
              >
                {service.duration * service.quantity}
              </div>

              {service.predecessors?.length > 0 && (
                <div className="predecessors">Predecessors: {service.predecessors.join(', ')}</div>
              )}
            </div>
          ))}


        </div>
      </div>
    </div>
  );
};

export default Timeline;
