import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ServicesTable from '../components/ServicesTable';
import MaterialsTable from '../components/MaterialsTable';
import { filterItems } from '../components/FilterData';
import { Adsense } from '@ctrl/react-adsense';
import SinapiReference from '../components/SinapiReference';
import './styles/sinapiViewer.css';

const API_URL =  process.env.REACT_APP_API_URL || 'http://localhost:5000/';

const SinapiViewer = () => {
  const [baseData, setBaseData] = useState([]);
  const [materialsData, setMaterialsData] = useState([]);
  const [expandedServices, setExpandedServices] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 300;

  const [filterText, setFilterText] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [showingServices, setShowingServices] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);

  const [servicesSettings, setServicesSettings] = useState({
    validPrice: false,
    validDuration: false,
  });
  
  const [materialsSettings, setMaterialsSettings] = useState({
    validPrice: false,
  });

  // State for SinapiReference data
  const [referenceData, setReferenceData] = useState({
    state: 'TO',
    month: 10,
    year: 2024,
    desoneracao: 'false',
  });

  const isNumber = (value) => {
    return typeof value === 'number' && !isNaN(value) && value > 0;
  };
  

  useEffect(() => {
    // Filter the items based on filter text first
    const filtered = filterItems(filterText, baseData, materialsData, showingServices);
  
    // Now apply the settings-based filtering on top
    let filteredItems;
  
    if (showingServices) {
      // For services
      filteredItems = filtered.filter(service => {
        let isValid = true;
  
        // Check if validPrice is enabled and if service has a valid price (custo_total)
        if (servicesSettings.validPrice && !isNumber(service.custo_total)) {
          isValid = false;
        }
  
        // Check if validDuration is enabled and if service has a valid duration
        if (servicesSettings.validDuration && !isNumber(service.duration)) {
          isValid = false;
        }
  
        return isValid;
      });
      setFilteredServices(filteredItems); // Set the filtered services
    } else {
      // For materials
      filteredItems = filtered.filter(material => {
        // Check if validPrice is enabled and if material has a valid price
        if (materialsSettings.validPrice && !isNumber(material.price)) {
          return false; // Remove invalid material (no valid price)
        }
  
        return true; // Keep valid material
      });
      setFilteredMaterials(filteredItems); // Set the filtered materials
    }
  
    setCurrentPage(1); // Reset to the first page after filtering
  }, [filterText, baseData, materialsData, showingServices, servicesSettings, materialsSettings]);
  

  const [isWideScreen, setIsWideScreen] = useState(false);
  useEffect(() => {
    // Check window width on component load
    if (window.innerWidth >= 900) {
      setIsWideScreen(true);
    }
  }, []); // Empty dependency array ensures this runs only once when the component mounts


const handleSearch = async () => {
  const { state, month, year, desoneracao } = referenceData;
  if (!state || !month || !year) return;

  setIsLoading(true);
  setNoDataFound(false);

  // Create a unique cache key based on the search parameters
  const cacheKey = `@cc-V2-sinapiData-${state}-${month}-${year}-${desoneracao}`;
  const timestampKey = `${cacheKey}-timestamp`; // A separate key to store the timestamp
  const oneDayInMs = 24 * 60 * 60 * 1000; // 1 day in milliseconds
  
  //const cachedData = localStorage.getItem(cacheKey);
  const cachedData = null;
  const cachedTimestamp = localStorage.getItem(timestampKey);
  
  if (cachedData && cachedTimestamp) {
      const isExpired = Date.now() - parseInt(cachedTimestamp, 10) > oneDayInMs;
  
      if (isExpired) {
          console.log('Cache expired for:', cacheKey);
          // Remove expired cache
          localStorage.removeItem(cacheKey);
          localStorage.removeItem(timestampKey);
      } else {
          console.log('Returning cached data for:', cacheKey);
          const elapsedTime = Date.now() - parseInt(cachedTimestamp, 10);
          console.log(`Cache hit: ${elapsedTime} ms`);
          
          // Use the cached data
          return JSON.parse(cachedData);
      }
  }

  if (cachedData) {
    // Parse the cached data and update the state
    const { services, materials } = JSON.parse(cachedData);
    setBaseData(services);
    setMaterialsData(materials);
    setFilteredServices(services);
    setFilteredMaterials(materials);
    setIsLoading(false);
  } else {
    try {
      console.log('Fetching new data');

      // Fetch both materials and services in a single call
      const response = await axios.get(`${API_URL}api/sinapiViewerData?state=${state}&month=${month}&year=${year}&desoneracao=${desoneracao}`);
      const { services, stringList, materials } = response.data;

      console.log(response.data);
      if (materials.length === 0) {
        setNoDataFound(true);
        return;
      }

      // Prepare materials as a lookup by code for quick access
      const materialMap = new Map(materials.map(material => {
        // Update material prices if the price is zero
        let price = material.price;
        if (price === 0) {
          // Use spPriceD or spPriceND based on desoneracao flag
          price = desoneracao === 'true' ? material.spPriceD : material.spPriceND;
          // Update the price in the material data as well
          material.price = price;
        }
        return [material.code, price]; // Map code to updated price
      }));

      // Dictionary of services by code
      const serviceMap = new Map(services.map(service => [service.service_code, { ...service, custo_total: null }]));

      const failedServices = []; // Services that failed to calculate

// Initialize processedServices as a Map
const processedServices = new Map(); 

// Function to calculate the total for a service
function calculateServiceTotal(service, processedServices) {
  let custo_total = 0;

  for (const component of service.components) {
    const { c, m, t } = component;
    let price;

    if (!t) {
      // Material component: lookup price in materialMap
      price = materialMap.get(c);
      if (price === undefined || price <= 0) {
        
        return null; // Return null if price is invalid or 0
      }
    } else {
      // Service dependency: check if the service dependency is resolved
      const dependency = processedServices.get(c);
      if (!dependency || !dependency.custo_total) {
        console.log(`Service dependency ${c} unresolved or not yet processed.`);
        return null; // Return null if dependency is unresolved
      }
      price = dependency.custo_total; // Get price from resolved service's custo_total
    }

    // Accumulate total cost for the component
    custo_total += price * m;
  }

  return custo_total;
}

// Resolve services in an iterative manner
function resolveServices(services) {
  let remainingServices = [...services];

  // Step 1: Process material-only services first (those with no dependencies)
  remainingServices.forEach((service, index) => {
    if (service.components.every(component => !component.t)) {
      const total = calculateServiceTotal(service, processedServices);
      if (total !== null) {
        service.custo_total = total;
        processedServices.set(service.service_code, service); // Add resolved service to Map
        remainingServices.splice(index, 1); // Remove from remaining services
      }
    }
  });

  // Step 2: Process remaining services with dependencies
  while (remainingServices.length > 0) {
    let anyResolved = false;

    remainingServices = remainingServices.filter(service => {
      const canResolve = service.components.every(component => {
        if (component.t) {
          return processedServices.has(component.c); // Check if service/component is resolved
        }
        return true; // Material component, no need to check resolved services
      });

      if (canResolve) {
        // Resolve the service
        const total = calculateServiceTotal(service, processedServices);
        if (total !== null) {
          service.custo_total = total;
          processedServices.set(service.service_code, service); // Add resolved service to Map
          anyResolved = true;
          return false; // Remove resolved service from remaining list
        }
      }

      return true; // Keep unresolved service in the remaining list
    });

    if (!anyResolved) {
      console.warn('Circular dependency detected or incomplete data.');
      console.log(remainingServices)
      break; // Stop if no services were resolved in this iteration
    }
  }

  return services;
}

// Start the service resolution process
const updatedServices = resolveServices(Array.from(serviceMap.values()));

// Update state with resolved services
setBaseData(updatedServices); // Update base data with resolved services
setFilteredServices(updatedServices); // Update filtered services for display

// Also update the materials with the updated prices
setMaterialsData(materials); // Ensure the updated materials are reflected in the UI
setFilteredMaterials(materials); // Ensure the filtered materials are reflected in the UI

      // Handle failed services: This can be logged, retried, or displayed to the user
      if (failedServices.length > 0) {
        console.warn('Failed services:', failedServices);
        // Optionally set this in the state to display or retry later
        // setFailedServices(failedServices);
      }

      // Cache the response data in localStorage
      /*localStorage.setItem(cacheKey, JSON.stringify({ 
        services: services, 
        timestamp: Date.now() 
      }));*/
    
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }
};

  const handleReferenceChange = (updatedData) => {
    setReferenceData((prev) => ({
      ...prev,
      ...updatedData,
    }));
  };

  const handleClosePopup = () => {
    setNoDataFound(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil((showingServices ? filteredServices : filteredMaterials).length / itemsPerPage);
  const currentItems = (showingServices ? filteredServices : filteredMaterials).slice(indexOfFirstItem, indexOfLastItem);

  const toggleExpandService = (uniqueIndex) => {
    setExpandedServices((prev) => ({
      ...prev,
      [uniqueIndex]: !prev[uniqueIndex],
    }));
  };

  return (
    <div>
      <div className="fixed-header">
        <h1>Consulta SINAPI</h1>
        <div className="header-content">
        {!isWideScreen && (
              <Adsense
                style={{ display: 'block', minWidth: '300px', maxHeight: '350px', maxWidth: '50vw', margin: 'auto' }}
                client="ca-pub-2344773042588967"
                slot="1408413103"
                data-ad-format="rectangle, horizontal"
                data-full-width-responsive="true"
              />
            )}
          <div className="header-left">
            <SinapiReference
              state={referenceData.state}
              month={referenceData.month}
              year={referenceData.year}
              desoneracao={referenceData.desoneracao}
              setState={(value) => handleReferenceChange({ state: value })}
              setMonth={(value) => handleReferenceChange({ month: value })}
              setYear={(value) => handleReferenceChange({ year: value })}
              setDesoneracao={(value) => handleReferenceChange({ desoneracao: value })}
              handleSearch={handleSearch}
              searchButton={true}
            />
          </div>
          <div className="header-center">
            <div className="button-group">
              <button
                className={!showingServices ? 'active' : ''}
                onClick={() => setShowingServices(false)}
                disabled={ baseData.length === 0}
                style={{
                  backgroundColor: isLoading || baseData.length === 0 ? '#d3d3d3' : '',
                  color: baseData.length === 0 ? '#999' : '',
                  cursor: baseData.length === 0 ? 'not-allowed' : 'pointer',
                }}
              >
                Insumos
              </button>
              <button
                className={showingServices ? 'active' : ''}
                onClick={() => setShowingServices(true)}
                disabled={ baseData.length === 0}
                style={{
                  backgroundColor: isLoading || baseData.length === 0 ? '#d3d3d3' : '',
                  color: baseData.length === 0 ? '#999' : '',
                  cursor: baseData.length === 0 ? 'not-allowed' : 'pointer',
                }}
              >
                Composições
              </button>
            </div>
            <div className="center-input-container">
              <input
                type="text"
                value={filterText}
                placeholder="Escreva os termos para filtrar"
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>

          {isWideScreen && ( 
            <div className="header-right">
              <Adsense
                style={{ display: 'block', minWidth: '300px', maxHeight: '350px', maxWidth: '50vw', margin: 'auto' }}
                client="ca-pub-2344773042588967"
                slot="1408413103"
                data-ad-format="rectangle, horizontal"
                data-full-width-responsive="true"
              />
          </div>
        )}
        </div>

        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => setCurrentPage(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}

        </div>
        <div className="filters">
          {showingServices ? (
            <div className="filter-options">
              <label>
                <input
                  type="checkbox"
                  checked={servicesSettings.validPrice}
                  onChange={(e) =>
                    setServicesSettings((prev) => ({
                      ...prev,
                      validPrice: e.target.checked,
                    }))
                  }
                />
                Ocultar Itens sem Preço
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={servicesSettings.validDuration}
                  onChange={(e) =>
                    setServicesSettings((prev) => ({
                      ...prev,
                      validDuration: e.target.checked,
                    }))
                  }
                />
                Ocultar Itens sem Duração
              </label>
            </div>
          ) : (
            <div className="filter-options">
              <label>
                <input
                  type="checkbox"
                  checked={materialsSettings.validPrice}
                  onChange={(e) =>
                    setMaterialsSettings((prev) => ({
                      ...prev,
                      validPrice: e.target.checked,
                    }))
                  }
                />
                Ocultar Itens sem Preço
              </label>
            </div>
          )}
        </div>
        <div>Resultados encontrados: {showingServices ? filteredServices.length : filteredMaterials.length}</div>
        <div className='super-container'>
        {isLoading && (
            <div className="overlay">
              <div className="spinner"></div>
            </div>
          )}
        <div className="table-container">

          <div className="scrollable">
            {showingServices ? (
              <ServicesTable
                services={currentItems.map((service) => ({
                  ...service,
                  uniqueIndex: service.service_code,
                }))}
                expandedServices={expandedServices}
                toggleExpandService={toggleExpandService}
                baseData={baseData}
                materialsData={materialsData}
              />
            ) : (
              <MaterialsTable materials={currentItems} />
            )}
          </div>
        </div>

        </div>

        {noDataFound && (
          <div className="popup">
            <div className="popup-content">
              <p>Não foram encontrados dados para esta pesquisa.</p>
              <button onClick={handleClosePopup}>Fechar</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SinapiViewer;
