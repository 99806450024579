import React, { useState } from 'react';

const SinapiReference = ({ state, month, year, desoneracao, setState, setMonth, setYear, setDesoneracao, handleSearch, searchButton }) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2024 + 1 }, (_, index) => 2024 + index);

  // Local state for YYYY/MM input
  const [monthYear, setMonthYear] = useState(`2024/10`);

  const handleMonthYearChange = (e) => {
    const value = e.target.value;
    setMonthYear(value);

    const [year, month] = value.split('/');
    if (year && month) {
      console.log(year, month)
      setYear(parseInt(year)); // Set year
      setMonth(parseInt(month)); // Set month
    }
  };

  return (
    <div className="input-container">

      {/* State Select */}
      <select value={state} onChange={(e) => setState(e.target.value)} className="select-field">
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
      </select>

      {/* Month/Year Select */}
      <select value={monthYear} onChange={handleMonthYearChange} className="select-field">
        <option value="2024/10">2024/10</option>
        <option value="2024/9">2024/09</option>
        <option value="2024/8">2024/08</option>
        {/* Add more options if necessary */}
      </select>

      {/* Desoneracao Select */}
      <select value={desoneracao} onChange={(e) => setDesoneracao(e.target.value)} className="select-field">
        <option value={false}>Não Desonerado</option>
        <option value={true}>Desonerado</option>
      </select>

      {searchButton ? <button onClick={handleSearch} className="search-button">Pesquisar</button> : ''}
    </div>
  );
};

export default SinapiReference;
