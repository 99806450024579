import React, { useState } from 'react';

const EditServiceModal = ({ service, onSave, onClose, servicesData }) => {
    const [editedService, setEditedService] = useState(service);
  
    console.log(service)
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedService((prev) => ({
            ...prev,
            [name]: name === 'quantity' || name === 'duration' ? parseFloat(value) : value,
            predecessors: name === 'predecessor' ? [value] : prev.predecessors, // Update predecessors array
        }));
    };
  
    const handleSave = (type) => {
      onSave(editedService, type);
      onClose();
    };
  
    return (
      <div className="modal">
        <h3>Edit Service</h3>
        <label>
          Duration:
          <input type="number" name="duration" value={editedService.duration} onChange={handleChange} />
        </label>
        <label>
          Quantity:
          <input type="number" name="quantity" value={editedService.quantity} onChange={handleChange} />
        </label>
        <label>
          Predecessor:
          <select name="predecessor" value={editedService.predecessors[0] || ''} onChange={handleChange}>
            <option value="">None</option>
            {servicesData.map((s) => (
              <option key={s.id} value={s.id}>
                {s.service_code}
              </option>
            ))}
          </select>
        </label>
        <button onClick={() => handleSave('default')}>Salvar</button>
        <button onClick={() => handleSave('isolated')}>Salvar Isoladamente</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    );
  };
  
  export default EditServiceModal;